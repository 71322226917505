

import { Component, Watch, Prop } from 'vue-property-decorator';
import ImportComponent from '../components/ImportComponent.vue';
import PayeeHelper from '../components/PayeeHelper';
import { VendorCategory } from '../components/PayeeHelper';
import { AddressMeta, Vendor } from '../store/models';

@Component
export default class ImportPayee extends ImportComponent {

  private helper: PayeeHelper = new PayeeHelper(VendorCategory.None);

  protected isFieldsLoaded(): boolean {
    return (!this.helper.isLoading);
  }

  protected mounted() {
    this.loadSchemas();
    this.helper.populateDataLists(this.currentOrg);
  }

  protected getConvertedJsonRecord(record: any): any {
    const vendor: Vendor = {
      organization: {
        orgName: record.orgName ? record.orgName : '',
        description: record.description ? record.description : '',
        contactPerson: record.contactPerson ? record.contactPerson : '',
        contactEmail: record.contactEmail ? record.contactEmail : '',
        contactPhone: record.contactPhone ? record.contactPhone : '',
        addresses: this.getAddresses(record),
      },
      vendor: {
        vendorCode: record.vendorCode,
        defaultExpenseClass: record.defaultExpenseClass,
        impactTargets: {
          indigenousProjectBenefit: 0,
          indigenousCommunityContribution: 0,
          indigenousEmployment: 0,
          socialProcurement: 0,
          diverseProjectBenefit: 0,
          diverseCommunityContribution: 0,
          diverseEmployment: 0,
        },
      },
      diversityDeclarations: {
        indigenousImpact: {
          values: record.indigenousImpact ? [ record.indigenousImpact ] : [],
        },
        impactESG: {
          values: record.impactESG ? [ record.impactESG ] : [],
        },
        impactStatus: {
          values: record.impactStatus ? [ record.impactStatus ] : [],
        },
        impactSocial: {
          values: record.impactSocial ? [ record.impactSocial ] : [],
        },
        socialFocusedOrgs: {
          values: record.socialFocusedOrgs ? [ record.socialFocusedOrgs ] : [],
        },
      },
    };
    return vendor;
  }

  protected getSchemas(): string[] {
    return [ 'general', 'create', 'organization' ];
  }

  protected getFields(): any[] {
    return [
      this.getField('orgName', 'Organization Name'),
      this.getField('vendorCode', 'Vendor ID'),
      this.getField('description', 'Description'),
      this.getField('contactPerson', 'Contact Name'),
      this.getField('contactEmail', 'Contact Email'),
      this.getField('contactPhone', 'Contact Phone Number'),
      this.getField('defaultExpenseClass', 'Primary Expense Class', this.getOptionsFromRefData(this.helper.defaultExpenseClasses)),
      this.getField('address1', 'Address - Line 1'),
      this.getField('address2', 'Address - Line 2'),
      this.getField('address3', 'Address - Line 3'),
      this.getField('orgCity', 'Address - City'),
      this.getField('stateOrProvince', 'Address - Province'),
      this.getField('postalCode', 'Address - Postal Code'),
      this.getField('countryName', 'Address - Country'),
    ];
  }

  protected getType(): string {
    return 'Payee';
  }

  protected getSchemaType(): string {
    return 'vendor';
  }

  private getAddresses(record: any): AddressMeta[] {
    const addrLines: string[] = [];
    if (record.address1) { addrLines.push(record.address1); }
    if (record.address2) { addrLines.push(record.address2); }
    if (record.address3) { addrLines.push(record.address3); }
    return [
    {
      addressCode: 'primary',
      addressName: 'Primary',
      addrLines,
      orgCity: record.orgCity ? record.orgCity : '',
      stateOrProvince: record.stateOrProvince ? record.stateOrProvince : '',
      postalCode: record.postalCode ? record.postalCode : '',
      countryName: record.countryName ? record.countryName : '',
    },
    ];
  }

  @Watch('helper.isLoading')
  private onHelperIsLoadingChanged(val: any, oldVal: any) {
    this.launchIfLoaded();
  }
}
